.mobilemenu
	width: 100vw
	&__items
		position: absolute
		top: 0
		left: 0
		display: flex
		z-index: 2		
		padding-top: 120px
		background-color: $black
		opacity: 0.7
		height: 100vh
		width: 100vw
		transform: translate(-100%)
		display: flex
		flex-direction: column
		transition: transform 0.5s ease-in-out
		text-align: center
		li
			margin-bottom: 1.2rem
			font-size: 1.5rem
			font-weight: 500

.activeMenu
	transform: translateX(0)

.hamburger
	display: flex
	justify-content: space-between
	align-items: center
	height: 62px
	max-width: 1050px
	width: 100%
	margin: auto
	li
		list-style: none
	a
		text-decoration: none
		font-family: 'Mulish', sans-serif
		font-weight: 700
		font-size: 36px
		color: $orange
		margin-bottom: 1.2rem
		&:hover
			font-weight: bolder
	&__checkbox
		position: absolute
		display: block
		height: 32px
		width: 32px
		top: 20px
		left: 20px
		z-index: 5
		opacity: 0
		cursor: pointer
		margin: 0

	&__lines
		display: block
		height: 26px
		width: 32px
		position: absolute
		top: 20px
		left: 20px
		z-index: 4
		display: flex
		flex-direction: column
		justify-content: space-between
		.line
			display: block
			height: 4px
			width: 100%
			background: $orange
			&__line1
				transform-origin: 0% 0%
				transition: transform 0.4s ease-in-out
			&__line2
				transition: transform 0.2s ease-in-out
			&__line3
				transform-origin: 0% 100%
				transition: transform 0.4s ease-in-out
			&--line1
				transform: rotate(43deg)
			&--line2
				transform: scaleY(0)
			&--line3
				transform: rotate(-46deg)

.activeHamburger


	// input[type="checkbox"]:checked ~ .mobilemenu__items
	// 	transform: translateX(0)

	// input[type="checkbox"]:checked ~ .hamburger__lines .line--line1
	// 	transform: rotate(45deg)

	// input[type="checkbox"]:checked ~ .hamburger__lines .line--line2
	// 	transform: scaleY(0)

	// input[type="checkbox"]:checked ~ .hamburger__lines .line--line3
	// 	transform: rotate(-45deg)




@media ( max-width:  769px)
	.mobilemenu
		// display: block
		position: absolute
		top: 0
		z-index: 3

		
@media screen and ( min-width:  420px)
	.mobilemenu
		&__items
			width: 75vw

@media screen and ( min-width:  550px)
	.mobilemenu
		&__items
			width: 60vw

@media screen and ( min-width:  769px)
	.mobilemenu
			display: none
		