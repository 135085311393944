.mainpage
    min-height: 100vh
    // margin-left: 80px
    // background: $skyblueGradient
    // padding-top: 210px
    // opacity: 0
    // z-index: 1
    // transform-style: preserve-3d
    // animation: fadeIn 1s 1s forwards
    // background-size: 1980px 1080px
    &__wrapper
        // position: absolute
        // top: 40%
        // left: 0
        padding-top: 40vh
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        // padding-top: 210px

    &__middle, &__start, &__end
        // margin-top: 20px
        margin: 10px 0
        font-family: 'Mulish', sans-serif
        font-weight: 700
        font-size: 40px
        will-change: contents
        opacity: 0
        z-index: 1
        // color: $grey
        color: #fff
        transform-style: preserve-3d
        animation: fadeInUp 1s 0.7s forwards

        &--purple
            font-weight: 700
            color: $purple

    &__start, &__end
        margin: 0
        // font-family: 'Mulish', sans-serif
        // font-weight: 700
        font-size: 22px
        // line-height: 24px
        animation: fadeInDown 1s 1s forwards
    
    &__end
        animation: fadeInUp 1s 1.3s forwards


    &__btns
        margin-top: 25px
        display: flex
        align-items: center
        justify-content: space-between
        width: 300px
        opacity: 0
        will-change: contents
        z-index: 1
        transform-style: preserve-3d
        animation: fadeInUp 1s 1.7s forwards

    &__link
        font-family: 'Mulish', sans-serif
        font-weight: 700
        font-size: 20px
        text-decoration: none
        color: $orange
        padding: 12px 15px

        &:hover
            text-decoration: none
    
    &__photo
        width: 40vw
        position: absolute
        bottom: 0
        right: 10vw
        opacity: 0
        will-change: contents
        z-index: 1
        transform-style: preserve-3d
        animation: fadeIn 1s 1.5s forwards

.btn
    background: $orange
    color: $black
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3)
    

// @media screen and ( max-width:  771px)
//     .mainpage
//         // margin: 0 auto

@media ( max-width:  2100px)
    .mainpage
        &__wrapper
            align-items: center
            margin-left: 5vw
        &__start, &__end, &__middle, &__btns
            text-align: center

@media ( max-width:  855px)
    .mainpage
        &__wrapper
            // display: flex
            align-items: flex-end
            // position: relative
            padding-top: 35vh
            margin: 0 auto
            width: 95%
        &__start, &__end, &__middle, &__btns
            font-size: 20px
            // padding-right: 7vw
            // width: 80%
            text-align: right
        &__middle
            font-size: 32px
            
@media ( max-width:  350px)
    .mainpage
        &__btns
            width: 275px

@media ( max-width:  300px)
    .mainpage
        &__btns
            flex-direction: column
        &__link
            margin-top: 10px




// @media screen (max-width: 100px)
