.nav-bar
    background: #181818
    min-width: 80px
    height: 100%
    position: fixed
    top: 0
    z-index: 100
    overflow: auto
    // min-height: 500px

    nav
        display: block
        text-align: center
        position: absolute
        height: 210px
        top: 50%
        margin-top: -120px
        width: 100%
    
        a
            font-size: 22px
            color: $orange
            opacity: 0.8
            display: block
            line-height: 51px
            height: 51px
            position: relative
            text-decoration: none
            
            svg
                transition: all 0.5s ease-out

            &:hover
                color: $skyblue

                svg
                    opacity: 0

                &::after
                    opacity: 1

            &::after
                content: ''
                font-size: 12px
                letter-spacing: 2px
                position: absolute
                bottom: 0
                display: block
                width: 100%
                text-align: center
                opacity: 0
                -webkit-transition: all 0.5s ease-out
                transition: all 0.5s ease-out

            // &:first-child
            //     &:after
            //         content: 'Домой'
            
        a.active
            svg
                color: $orange




    ul
        position: absolute
        bottom: 20px
        width: 100%
        display: block
        padding: 0
        list-style: none
        text-align: center
        margin: 0

        li
            a
                padding: 10px 0
                display: block
                font-size: 15px
                line-height: 16px

                svg
                    font-size: 22px
                    color: $orange
                    opacity: 0.8

                    &:hover
                        color: $skyblue
                        transition: all 0.5s ease-out

#home-link
    &:after
        content: 'Домой'
        color: $orange
        
#about-link
    &:after
        content: 'Обо мне'
        color: $orange

#contact-link
    &::after
        content: 'Контакты'
        color: $orange


@media ( max-width:  768px)
    .nav-bar
        display: none
        // background: #181818
        // width: 40px
        // height: 100%
        // position: absolute
        // top: 0
        // z-index: 3
        // min-height: 500px