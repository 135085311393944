.page
	width: 100%
	height: 100%
	position: absolute
	// margin-left: 80px

.container 
	width: 70vw
	// height: 90%
	// min-height: 566px
	margin: 0 auto
	// margin-top: 210px
	// padding-top: 120px

@media screen and ( max-width:  855px)
	.container
		width: 100vw

// @media screen and ( max-width:  375px)
// 	.container
// 		width: 90vw