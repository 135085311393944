.about
	// min-height: 100%
	// background: $dark
	padding-top: 100px
	transition: all 0.5s ease-in-out
	// width: 100vw

	&__wrapper
		// display: grid
		grid-template-columns: auto
		grid-template-rows: auto
		column-gap: 50px
		row-gap: 50px
		grid-template-areas: 'photo description''photo skills'


.photo
	grid-area: photo
	min-width: 250px
	max-width: 530px
	opacity: 0
	will-change: contents
	animation: fadeIn 2s 1.2s forwards
	img
		width: 100%

.description
	grid-area: description
	// opacity: 0
	// will-change: contents
	// z-index: 1
	// animation: fadeInUp 1s 0.7s forwards
	&__title
		position: relative
		font-size: 20px
		font-weight: 700
		background: transparent
		will-change: contents
		opacity: 0
		// z-index: 1
		animation: fadeInUp 1s 0.5s forwards
		&::before
			@include beforeOrangeCircle

	&__subtitle
		margin-top: 20px
		font-size: 36px
		font-weight: 700
		will-change: contents
		opacity: 0
		animation: fadeInUp 1s 1s forwards
	&__text
		font-size: 18px
		margin-top: 20px
		will-change: contents
		opacity: 0
		animation: fadeInUp 1s 1.5s forwards
.skills
	grid-area: skills
	display: flex
	flex-wrap: wrap
	margin-left: 80px

	&__item
		width: 260px
		margin: 0 20px 20px 0
		padding: 15px
		border: 2px solid $orange
		border-radius: 15px
		// will-change: contents
		// opacity: 0
		// animation: fadeInUp 1s 2.5s forwards

	@for $i from 1 through 3
		&__item:nth-child(#{$i})
			will-change: contents
			opacity: 0
			animation: fadeInUp 0.7s 1.3s + 0.3s * $i forwards
			// animation-delay: 5s

	&__title
		display: flex
		justify-content: space-between
		align-items: center

		h3
			font-size: 22px
			font-weight: 700			
	
	&__icon
		width: 30px
		height: 30px
		position: relative
		margin-right: 10px
		z-index: 0
		&::before
			@include beforeOrangeCircle


.horizontalLine
	position: absolute
	top: 608px
	left: 0
	z-index: 5
	// border-top: 
	height: 80px
	width: 70vw
	opacity: 0.9
	background: $orangeGradient
	transform: rotate(5deg)
	animation: fadeInLeft 2s forwards
	// border-radius: 4px

@media screen and (min-width:  769px)
	.about
		// width: calc( 100vw - 80px )
		// margin-left: 80px

@media screen and (max-width:  2600px)
	.about
		.container
			width: 70%
		&__wrapper
			display: flex
			flex-wrap: wrap
			// justify-content: space-around
			justify-content: center
	.description
		width: 45%
	.photo
		// display: none
		width: 55%
	.skills
		// margin-top: 20px
		align-items: center
		&__item
			width: 44%
			margin: 20px 20px 0 0
			padding: 5px
		&__title
			justify-content: center

@media (max-width: 1506px)
	.photo
		max-width: 430px
	.horizontalLine
		top: 512px
		height: 60px

@media (max-width: 1246px)
	.photo
		max-width: 380px
		padding-top: 50px


@media screen and (max-width:  1200px)
	.about
		.container
			width: 90%
		&__wrapper
			display: flex
			flex-wrap: wrap
	.description
		width: 45%
	.photo
		// display: none
		width: 45%
	.skills
		// margin-top: 20px
		align-items: center
		&__item
			width: 44%
			margin: 20px 20px 0 0
			padding: 5px
		&__title
			justify-content: center

@media (max-width: 1024px)
	.photo
		display: none
	.horizontalLine
		display: none
	.description
		width: 80%

@media screen and (max-width:  768px)
	.photo
		// display: none
		width: 30vw
	.about
		padding-top: 60px
		&__wrapper
			display: flex
			flex-wrap: wrap
			width: 100%
			margin: 0 auto
			// column-gap: 0
	.description
		width: 90%
	
	.skills
		margin: 0
		align-items: center
		&__item
			width: 44%
			margin: 20px 20px 0 0
			padding: 5px
		&__title
			justify-content: center
	
@media screen and (max-width:  701px)
	.photo
		display: none
	.description
		width: 90%
	.about
		padding-top: 60px
		&__wrapper
			// width: 90%
			margin: 0 auto
			// column-gap: 0
	.skills
		// margin-top: 20px
		align-items: center
		&__item
			width: 80%
			margin: 10px auto
			padding: 5px
		&__title
			justify-content: center

